
<template>
  <div>
    <v-container>
      <!-- Loading State -->
      <v-row v-if="loading">
        <v-col cols="12">
          <v-skeleton-loader type="table"></v-skeleton-loader>
        </v-col>
      </v-row>


      <template v-else>
                <!-- Filters and Search -->
                <v-row>
  <v-col cols="12" md="4">
    <!-- Status Filter Dropdown -->
    <v-select
      v-model="selectedStatus"
      :items="statusOptions"
      label="Filter by Status"
      outlined
      dense
      clearable
    ></v-select>
  </v-col>

  <v-col cols="12" md="4">
    <!-- Plan Type Filter Dropdown -->
    <v-select
      v-model="selectedPlanType"
      :items="planTypeOptions"
      label="Filter by Plan Type"
      outlined
      dense
      clearable
    ></v-select>
  </v-col>

  <v-col cols="12" md="4">
    <!-- Search by Business Name -->
    <v-text-field
      v-model="searchQuery"
      label="Search by Business Name"
      outlined
      dense
      clearable
      prepend-inner-icon="mdi-magnify"
    ></v-text-field>
  </v-col>
</v-row>

      <!-- Data Table -->
      <v-row >
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="filteredSubscriptions"
            :items-per-page.sync="subscriptions.per_page"
            :server-items-length="subscriptions.total"
            :page.sync="subscriptions.current_page"
            class="elevation-1"
          >
            <!-- User Column -->
            <template v-slot:item.user="{ item }">
              <div>
                <strong>{{ item.user.name }}</strong><br />
                <small>{{ item.user.email }}</small>
              </div>
            </template>

            <!-- Plan Column -->
            <template v-slot:item.plan="{ item }">
              <div>
                <strong>{{ item.plan.name }}</strong><br />
                <small>£{{ item.plan.price }}</small>
              </div>
            </template>

            <!-- Status Column -->
            <template v-slot:item.status="{ item }">
              <v-chip :class="getStatusClass(item.status)" dark>
                {{ item.status }}
              </v-chip>
            </template>

            <!-- Auto Renew Column -->
            <template v-slot:item.auto_renew="{ item }">
              <v-chip>
                {{ item.auto_renew ? 'Enabled' : 'Disabled' }}
              </v-chip>
            </template>

            <!-- Actions Column -->
            <template v-slot:item.actions="{ item }">
              <v-btn small class="bg-primary text-light" @click="openModal(item)">
                View
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      </template>

    </v-container>

    <!-- Subscription Details Modal -->
    <v-dialog v-model="modalOpen" max-width="800">
  <v-card v-if="selectedSubscription">
    <v-card-title class="headline">
      Subscription Details
    </v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>User</v-list-item-title>
            <v-list-item-subtitle>
              {{ selectedSubscription.user.name }} ({{ selectedSubscription.user.email }})
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Business</v-list-item-title>
            <v-list-item-subtitle>
              {{ selectedSubscription.business_name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Start Date</v-list-item-title>
            <v-list-item-subtitle>
              {{ selectedSubscription.start_date }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Next Renewal</v-list-item-title>
            <v-list-item-subtitle>
              {{ selectedSubscription.end_date }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Status</v-list-item-title>
            <v-list-item-subtitle>
              <v-chip :class="getStatusClass(selectedSubscription.status)" dark>
                {{ selectedSubscription.status }}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Auto Renew</v-list-item-title>
            <v-list-item-subtitle>
              {{ selectedSubscription.auto_renew ? 'Enabled' : 'Disabled' }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider class="my-4"></v-divider>

      <!-- Payment Table -->
      <v-card-title>Payment History</v-card-title>
      <v-data-table
        :headers="paymentHeaders"
        :items="selectedSubscription.payments"
        dense
        disable-pagination
  hide-default-footer

        class="elevation-1"
      >
        <template v-slot:item.status="{ item }">
          <v-chip :color="getPaymentStatusColor(item.status)" dark>
            {{ item.status }}
          </v-chip>
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="bg-primary text-white" @click="modalOpen = false">
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "BusinessSubscriptions",
  data() {
    return {
      paymentHeaders: [
      { text: "Amount", value: "amount" },
      { text: "Payment Method", value: "payment_method" },
      { text: "Transaction ID", value: "transaction_id" },
      { text: "Status", value: "status" },
      { text: "Date", value: "created_at" }
    ],
      loading: true, // Loading state
      searchTimeout: null,
      subscriptions: {
        data: [], // List of subscriptions
        total: 0, // Total number of subscriptions
        per_page: 10, // Items per page
        current_page: 1, // Current page
      },
      headers: [
        { text: "User", value: "user" },
        { text: "Business", value: "business_name" },
        { text: "Plan", value: "plan" },
        { text: "Status", value: "status" },
        { text: "Auto Renew", value: "auto_renew" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      modalOpen: false, // Controls modal visibility
      selectedSubscription: null, // Stores the selected subscription

      // Filter and Search Data
      selectedStatus: null, // Selected status filter
      selectedPlanType: null, // Selected plan type filter
      searchQuery: "", // Search query for business name
      statusOptions: ["active", "expired", "canceled"], // Status filter options
      planTypeOptions: ["Premium", "Pro"], // Plan type filter options
    };
  },
  computed: {
    // Filtered subscriptions based on selected filters and search query
    filteredSubscriptions() {
      return this.subscriptions.data.filter((subscription) => {
        const matchesStatus =
          !this.selectedStatus || subscription.status === this.selectedStatus;
        const matchesPlanType =
          !this.selectedPlanType || subscription.plan.name === this.selectedPlanType;
        const matchesSearch =
          !this.searchQuery ||
          subscription.business_name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());

        return matchesStatus && matchesPlanType && matchesSearch;
      });
    },
  },
  watch: {
    // Watch for changes in current_page
    "subscriptions.current_page": function (newPage) {
      this.fetchSubscriptions();
    },
    // Watch for changes in per_page
    "subscriptions.per_page": function (newPerPage) {
      this.fetchSubscriptions();
    },
    // Watch for changes in selectedStatus
    selectedStatus: function (newStatus) {
      this.fetchSubscriptions();
    },
    // Watch for changes in selectedPlanType
    selectedPlanType: function (newPlanType) {
      this.fetchSubscriptions();
    },
    // Watch for changes in searchQuery
        searchQuery(newQuery) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.fetchSubscriptions();
      }, 500); // Delay of 500ms
    }
  },
  mounted() {
    this.fetchSubscriptions();
  },
  methods: {
    // Fetch subscriptions from the API with filters
    async fetchSubscriptions() {
      this.loading = true;
      try {
        const { current_page, per_page } = this.subscriptions;
        
        console.log('per_page',per_page)
        // Construct the URL with query parameters
        const url = `admin/billing/get-business-subscriptions?page=${current_page}&per_page=${per_page}`;
        console.log('url',url)

// Add filter parameters to the URL
const queryParams = [];

if (this.selectedStatus) {
  queryParams.push(`status=${encodeURIComponent(this.selectedStatus)}`);
}

if (this.selectedPlanType) {
  queryParams.push(`plan_type=${encodeURIComponent(this.selectedPlanType)}`);
}

if (this.searchQuery) {
  queryParams.push(`search=${encodeURIComponent(this.searchQuery)}`);
}

// Construct the final URL with query parameters
const finalUrl = queryParams.length > 0 ? `${url}&${queryParams.join('&')}` : url;

// Fetch data using the constructed URL
const response = await ApiService.get(finalUrl);

        // Update subscriptions with paginated data
        this.subscriptions = {
          data: response.data.data,
          total: response.data.meta.total,
          per_page: response.data.meta.per_page,
          current_page: response.data.meta.current_page,
        };
      } catch (error) {
        console.error("Failed to fetch subscriptions:", error);
      } finally {
        this.loading = false;
      }
    },
    getPaymentStatusColor(status) {
    switch (status) {
      case 'completed': return 'green';
      case 'pending': return 'orange';
      case 'failed': return 'red';
      default: return 'grey';
    }
  },
  formatDate(dateString) {
    return new Date(dateString).toLocaleString(); // Formats as local date-time
  },
    // Get status class for the chip
    getStatusClass(status) {
      switch (status) {
        case "active":
          return "status-active";
        case "expired":
          return "status-expired";
        case "canceled":
          return "status-canceled";
        default:
          return "status-default";
      }
    },

    // Open modal and set selected subscription
    openModal(subscription) {
      this.selectedSubscription = subscription;
      this.modalOpen = true;
    },
  },
};
</script>
<style scoped>
/* Add custom styles if needed */
.v-data-table {
  margin-top: 20px;
}


.status-active {
  background-color: green !important;
  color: white;
}

.status-expired {
  background-color: red !important;
  color: white;
}

.status-canceled {
  background-color: orange !important;
  color: white;
}

.status-default {
  background-color: gray !important;
  color: white;
}

</style>