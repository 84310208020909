<template>
    <div>
      <h2 class="mt-4 mx-2">Domain Renewal Payments</h2>
      <v-container>
        <!-- Loading State -->
        <v-row v-if="loading">
          <v-col cols="12">
            <v-skeleton-loader type="table"></v-skeleton-loader>
          </v-col>
        </v-row>
  
        <!-- Data Table -->
         <template v-else>
          <v-row>
            
  <v-col cols="12" md="4">
    <!-- Search by Business Name -->
    <v-text-field
      v-model="searchQuery"
      label="Search by Business Name"
      outlined
      dense
      clearable
      prepend-inner-icon="mdi-magnify"
    ></v-text-field>
  </v-col>
          </v-row>
          <v-row >

<v-col cols="12">
  <v-data-table
    :headers="headers"
    :items="domainPayments.data"
    :items-per-page.sync="domainPayments.per_page"
    :server-items-length="domainPayments.total"
    :page.sync="domainPayments.current_page"
    class="elevation-1"
  >
    <!-- Business Column -->
    <template v-slot:item.business="{ item }">
      <div>
        <strong>{{ item.business.name }}</strong><br />
        <small>{{ item.business.email }}</small>
      </div>
    </template>

    <!-- Amount Column -->
    <template v-slot:item.amount="{ item }">
      £{{ item.amount }}
    </template>

    <!-- Status Column -->
    <template v-slot:item.status="{ item }">
      <v-chip :color="getStatusColor(item.status)" dark>
        {{ item.status }}
      </v-chip>
    </template>

    <!-- Actions Column -->
    <template v-slot:item.actions="{ item }">
      <v-btn small class="bg-primary text-white" @click="openModal(item)">
        View
      </v-btn>
    </template>
  </v-data-table>
</v-col>
</v-row>
         </template>

      </v-container>
  
      <!-- Domain Payment Details Modal -->
      <v-dialog v-model="modalOpen" max-width="600">
        <v-card v-if="selectedDomainPayment">
          <v-card-title class="headline">
            Domain Payment Details
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Business</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ selectedDomainPayment.business.name }} ({{ selectedDomainPayment.business.email }})
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Amount</v-list-item-title>
                  <v-list-item-subtitle>
                    £{{ selectedDomainPayment.amount }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Status</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip :color="getStatusColor(selectedDomainPayment.status)" dark>
                      {{ selectedDomainPayment.status }}
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Payment Intent ID</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ selectedDomainPayment.payment_intent_id }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Payment Date</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ selectedDomainPayment.created_at }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="bg-primary text-white" @click="modalOpen = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "DomainPayments",
  data() {
    return {
      loading: true, // Loading state
      searchQuery: "", // Search query for business name
      searchTimeout:null,
      domainPayments: {
        data: [], // List of domain payments
        total: 0, // Total number of domain payments
        per_page: 10, // Items per page
        current_page: 1, // Current page
      },
      headers: [
        { text: "Business", value: "business" },
        { text: "Amount", value: "amount" },
        { text: "Status", value: "status" },
        { text: "Payment Intent ID", value: "payment_intent_id" },
        { text: "Payment Date", value: "created_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      modalOpen: false, // Controls modal visibility
      selectedDomainPayment: null, // Stores the selected domain payment
    };
  },
  watch: {
    // Watch for changes in current_page
    "domainPayments.current_page": function (newPage) {
      this.fetchDomainPayments();
    },
    // Watch for changes in per_page
    "domainPayments.per_page": function (newPerPage) {
      this.fetchDomainPayments();
    },
    searchQuery(newQuery) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.fetchDomainPayments();
      }, 500); // Delay of 500ms
    }
  },
  mounted() {
    this.fetchDomainPayments();
  },
  methods: {
    // Fetch domain payments from the API
    async fetchDomainPayments() {
  this.loading = true;
  try {
    const { current_page, per_page } = this.domainPayments;

    // Construct the base URL with pagination parameters
    const url = `admin/billing/get-domain-payments?page=${current_page}&per_page=${per_page}`;

    // Add filter parameters to the URL
    const queryParams = [];

    if (this.searchQuery) {
      queryParams.push(`search=${encodeURIComponent(this.searchQuery)}`);
    }

    // Construct the final URL with query parameters
    const finalUrl = queryParams.length > 0 ? `${url}&${queryParams.join('&')}` : url;

    console.log('Fetching URL:', finalUrl); // Debugging: Log the final URL

    // Fetch data using the constructed URL
    const response = await ApiService.get(finalUrl);

    // Update domainPayments with paginated data
    this.domainPayments = {
      data: response.data.data,
      total: response.data.meta.total,
      per_page: response.data.meta.per_page,
      current_page: response.data.meta.current_page,
    };
  } catch (error) {
    console.error("Failed to fetch domain payments:", error);
  } finally {
    this.loading = false;
  }
},

    // Get status color for the chip
    getStatusColor(status) {
      switch (status) {
        case "completed":
          return "green";
        case "pending":
          return "orange";
        case "cancelled":
          return "red";
        default:
          return "gray";
      }
    },

    // Open modal and set selected domain payment
    openModal(domainPayment) {
      this.selectedDomainPayment = domainPayment;
      this.modalOpen = true;
    },
  },
};
</script>

<style scoped>
/* Add custom styles if needed */
.v-data-table {
  margin-top: 20px;
}
</style>