<template>
    <v-container class="mx-0">
      <!-- Stats Cards -->
      <v-row>
        <!-- Pro Plan Users Card -->

        <v-col cols="12" md="3" sm="6">
          <v-card class="d-flex flex-column" height="150" color="#03b397">
            <v-row align="center" class="ma-0 p-4">
              <v-col cols="auto" class="pa-0">
                <v-icon size="40" color="#ffff">mdi-account-star</v-icon>
              </v-col>
              <v-col class="pa-0">
                <v-card-title class="subtitle-1 pl-2">Pro Plan Users</v-card-title>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <div class="text-center pb-5">
                <v-card-text class="headline  my-0 font-weight-bold" style="font-size: 2rem;"> {{proPlanUsers }}</v-card-text>
            </div>
          </v-card>
        </v-col>
  
        <!-- Premium Plan Users Card -->
        <v-col cols="12" md="3" sm="6">
          <v-card class="d-flex flex-column" height="150" color="#4d4a9e">
            <v-row align="center" class="ma-0 p-4">
                <v-col cols="auto" class="p-2">
                <v-icon size="40" color="#ffff">mdi-account-supervisor</v-icon>
              </v-col>
              <v-col class="p-0">
                <v-card-title class="subtitle-1 pl-2">Premium Plan Users</v-card-title>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <div class="text-center pb-5">
                <v-card-text class="headline  my-0 font-weight-bold" style="font-size: 2rem;">{{   premiumPlanUsers }}</v-card-text>
            </div>
          </v-card>
        </v-col>
  

  
        <!-- Total Domain Renewal Amount Card -->
        <v-col cols="12" md="3" sm="6">
          <v-card class="d-flex flex-column" height="150" color="#4CAF50">
            <v-row align="center" class="ma-0 p-2">
              <v-col cols="auto" class="pa-0">
                <v-icon size="40" color="#ffff">mdi-domain</v-icon>
              </v-col>
              <v-col class="pa-0">
                <v-card-title class="subtitle-1 pl-2">Domain Renewal <br />Payments</v-card-title>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <div class="text-center pb-4">
              <v-card-text class="headline  my-0 font-weight-bold" style="font-size: 2rem;"> £{{ totalDomainRenewalAmount }}</v-card-text>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <v-card class="d-flex flex-column" height="150" color="#686868">
            <v-row align="center" class="ma-0 p-2">
              <v-col cols="auto" class="pa-0">
                <v-icon size="40" color="#ffff">mdi-domain</v-icon>
              </v-col>
              <v-col class="pa-0">
                <v-card-title class="subtitle-1 pl-2">Business Emails <br />Payments</v-card-title>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <div class="text-center pb-4">
              <v-card-text class="headline  my-0 font-weight-bold" style="font-size: 2rem;"> £{{ totalBusinessMailPaymentAmount }}</v-card-text>
            </div>
          </v-card>
        </v-col>
      </v-row>
  
      <!-- Tabs -->
      <v-tabs v-model="tab" class="mt-5">
        <v-tab>Business Subscriptions</v-tab>
        <v-tab>Domain Payments</v-tab>
        <v-tab>Business Mail Payments</v-tab>

      </v-tabs>
  
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <BusinessSubscriptions />
        </v-tab-item>
        <v-tab-item>
          <DomainPaymants />
        </v-tab-item>
        <v-tab-item>
          <BusinessMailPayments />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import BusinessSubscriptions from './BusinessSubscriptions.vue';
import DomainPaymants from './DomainPaymants.vue';
import BusinessMailPayments from "./BusinessMailPayments.vue";
import ApiService from "@/core/services/api.service";

export default {
  components: {
    BusinessSubscriptions,
    DomainPaymants,
    BusinessMailPayments
  },
  name: "billing",
  data() {
    return {
      tab: 0, // Default to the first tab
      proPlanUsers: 0, // Initialize to 0
      premiumPlanUsers: 0, // Initialize to 0
      totalBusinessMailPaymentAmount: 0, // Initialize to 0
      totalDomainRenewalAmount: 0, // Initialize to 0
      loading: true, // Loading state
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Billing" }]);
    this.loadstats(); // Fetch stats data
  },
  methods: {
    loadstats() {
      ApiService.get("admin/billing/stats")
        .then(({ data }) => {
          // Update data properties with API response
          this.proPlanUsers = data.pro_plan_users;
          this.premiumPlanUsers = data.premium_plan_users;
          this.totalBusinessMailPaymentAmount = data.totalBusinessMailPaymentAmount;
          this.totalDomainRenewalAmount = data.total_domain_renewal_amount;
          this.loading = false; // Set loading to false
        })
        .catch(() => {
          this.loading = false; // Set loading to false in case of error
        });
    }
  }
};
</script>
  
  <style scoped>
  /* Optional: Add custom styles if needed */
  .v-card {
    border-radius: 8px; /* Rounded corners */
  }
  .subtitle-1{
    color: white;
  }
  .headline{
    color: white;
  }
  </style>